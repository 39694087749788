import '../css/Footer.css';
import '../css/App.css';
import insta from "../images/logos/instagram-logo.png";

function Footer() {

    return (
        <div className='Footer'>
            <div className='Footer-links'>
                <div className='Footer-header'>
                    <h3> SEE MORE OF MY WORK: </h3>
                </div>
                <div className='Footer-link' id="Footer-instagram">
                    <img className="Footer-link-logo" src={insta} alt="Instagram Logo" href="https://instagram.com/jankypoker" />
                    <a target="_blank" rel="noreferrer" className="Footer-link"
                        href="https://instagram.com/jankypoker">
                        @jankypoker
                    </a>
                </div>
            </div>
        </div>
    )

} export default Footer;
