import '../css/FAQ.css';
import Navbar from '../bars/Navbar';
import Footer from '../bars/Footer';
import stupic from '../images/studio.png';

function FAQ() {


    return (
        <div className='FAQ'>
            <Navbar />
            <h1 className='FAQ-h'>
                FAQ
            </h1>
            <img className='FAQ-img' src={stupic} alt='studio' />

            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>where are you based?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>I am currently based in NYC and am guesting at various nyc-based studios. <i>Not in New York? Fill out my <a href='https://forms.gle/K7Jcvo3F4p1Dcp2Q7' rel="noreferrer" target='_blank'>waitlist form</a> to be notified if I come to your city</i></p>
                </div>
            </div>
            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>how do I book an appointment?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>My booking form is listed in under the 'BOOK' tab of this website! Fill this form out and I will get back to you as soon as possible <br /> <br />
                        I offer three kinds of appointments:<br /><br />
                        <b>flash:</b> flash is a selection of my pre-existing designs (which you can find under my 'FLASH' tab). your choice of any available flash piece(s) that are not marked as claimed. all my flash is tatted only once. i am open to minor modifications of my flash, let me know and I'll see if it is doable. i can also do variations of claimed flash for you in some cases, just ask! my flash is claimed once i receive your deposit—I will not reserve your requested flash if a deposit is not submitted or our appointment is cancelled. my work is not for free use! please do not bring my flash to another artist without permission.<br /><br />
                        <b>custom:</b> a design or concept you bring to me that i can tattoo if it fits my style and strengths— your or your friends' doodles are very welcome! I will not take work by other tattoo/design artists. I do not show designs before our appointment, so make sure to include as many details as possible in the booking form!<br /><br />
                        <b>touchups:</b> the first touchup is free for all tattoos done by me! these bookings go through a separate touchups form (also under my BOOK tab) which stays open always, and will receive priority. <br /><br />
                        All of my tattoos are handpoked (machine-free)! (<i>what is a handpoked tattoo?</i> See Andy (@bap_tat)'s super <a href='https://www.instagram.com/p/Cn0K9UGv_wa/' rel="noreferrer" target='_blank'>informative post</a>)
                        I work flash preferred—those booking for flash will receive priority!
                        I am self/community taught and still learning, please know that you are signing up for work that is not perfect!</p>
                </div>
            </div>

            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>i don't live in your area! do you sell existing/custom flash designs to be tattooed by another artist?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>dm me. pricing starts at $25 for lettering and $35 for more involved pieces.</p>
                </div>
            </div>

            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>what kind of custom pieces do you typically take?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>objects or portraits that would look good in high contrast duotone pieces, jewelry, trinkets, shells, botanical and floral pieces large scale text in ornamental or textured lettering handdrawn sketches or doodles. no pinterest/tiny tattoos</p>
                </div>
            </div>

            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>what are your rates?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>my rates now start at $150, you can expect most ~90 min session tattoos to cost around $150-275 depending on complexity, size, and placement.
                        I offer sliding scale for all bipoc/ufli/lgbtq clients—all my quotes are given as a range (eg. $150-250),  which will be will be sent out with your gcal invite along with other details like pre/aftercare, location, contact details <br /><br />
                        <i>What is sliding scale?</i> Consult this wonderful guide by A.B Moore (@v_a_l_l_e_y) on <a href='https://www.abmtattoo.com/sliding-scale-policy/' rel="noreferrer" target='_blank'>their website</a> </p>
                </div>
            </div>

            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>next steps after submitting a booking form?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>Stay tuned for an email from me! You will receive a google calendar event invitation along with the details of your appointment. I will require a non-refundable deposit of a third of your minimum deposit (typically $50-75) to confirm your appointment (which will go into the final cost of your tattoo).</p>
                </div>
            </div>
            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2>interested in working with/booking me for an event?</h2>
                </div>
                <div className='FAQ-a'>
                    <p>email me!</p>
                </div>
            </div>
            {/* 
            <div className='FAQ-wrap'>
                <div className='FAQ-q'>
                    <h2></h2>
                </div>
                <div className='FAQ-a'>
                    <p></p>
                </div>
            </div> 
            */}

            <div className='FAQ-wrap'>
                <p>Feel free to dm me or email me at jankypokertattoo@gmail.com if you have any other questions!</p>
            </div>
            <Footer />
        </div>
    );
}

export default FAQ;
