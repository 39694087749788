import '../css/Flash.css';
import Navbar from '../bars/Navbar';
import Footer from '../bars/Footer';
import icon from '../images/logos/newtab.png';
import tatt from '../images/flash.png';

function Flash() {

    return (
        <div className='Flash'>
            <Navbar />
            <img className='Flash-img' src={tatt} alt='tattoo' />
            <div className='Flash-body'>
                <div className='Flash-link'>
                    <a href='https://docs.google.com/presentation/d/1hq6ne_pEsnWjHliVexOXvbYpLVJyl06cJ8GuZ2d5tcU/edit?usp=sharing' rel="noreferrer" target='_blank'>current flash</a>
                    <img className='Flash-icon' src={icon} alt='new tab' />
                </div>
                <div className='Flash-link'>
                    <a href='https://docs.google.com/presentation/d/1ar-pVqtjo4LvweRmNGBXeOX34LWIP3jzHyidijlpkoA/edit?usp=sharing' rel="noreferrer" target='_blank'>archived flash</a>
                    <img className='Flash-icon' src={icon} alt='new tab' />
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Flash;
