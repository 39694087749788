import '../css/Home.css'
import Navbar from '../bars/Navbar'
import Footer from "../bars/Footer";
import me from '../images/me2.png'

function Home() {

    return (
        <div className="Home">
            <Navbar />
            <div className='Home-body'>
                <div className='Home-pic'>
                    <img className='Home-img' src={me} alt='artist' />
                </div>
                <h1 className='Home-bio-h1'> rena (she/her) </h1>
                <p className='Home-bio-p'>
                    jankypoker was conceived in middletown, rhode island in the fall of 2020 as a spontaneous passion project amidst lockdown monotony. my first clients were my roommates, who offered me their arms and legs as canvases for experimentation, which I dotted meticulously with care. the gentle intimacy of making handpoked art with my roommates inside a small yellow house is now something I am excited to bring to the studio as I greet new and familiar faces alike. If you decide to book with me, I look forward to meeting you, and hope you enjoy collecting my work as much as I enjoy making it! <br /> <br />
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default Home;