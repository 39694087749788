import Home from "./pages/Home";
import './css/App.css';

function App() {
  return (
    <div className="App">
      <Home className="Home" />
    </div>
  );
}

export default App;
