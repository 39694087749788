import '../css/About.css';
import Navbar from '../bars/Navbar';
import Footer from '../bars/Footer';
import me from '../images/about.png';

function Flash() {

    return (
        <div className='About'>
            <Navbar />
            <div className='About-body'>
                <img className='About-img' src={me} alt='tattoo' />
                <div className='About-blurb'>
                    <p>
                        I grew up in shanghai before moving to the US for college, where I graduated from Brown in 2023 with a degree in fine art and computer science. I moved to New York where I am working as a software engineer and tattoo artist. Outside of an office and a tattooing space, my home has become a studio of making, where sketches, paintings, burnt cakes, failed thrift flips, and other ambitious diy projects come together.
                    </p>
                </div>
            </div>
            <Footer />
        </div >
    );
}

export default Flash;
